/* Legislative Bill Tracker Styles */

.legislative-bill-tracker {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.legislative-bill-tracker .block-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--lbt-neutral-800);
}

/* Summary section styles */
.legislative-bill-tracker .summary-segment {
  margin-bottom: 1rem;
}

.legislative-bill-tracker .font-bold {
  font-weight: 700;
}

.legislative-bill-tracker .border-explanation {
  margin-left: 1rem;
  padding-left: 0.5rem;
  list-style-type: disc;
}

.legislative-bill-tracker .red-border-example,
.legislative-bill-tracker .orange-border-example {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  border-radius: 0.25rem;
}

.legislative-bill-tracker .red-border-example {
  background-color: #fee2e2;
}

.legislative-bill-tracker .orange-border-example {
  background-color: #ffedd5;
}

/* Filter section styles */
.legislative-bill-tracker .filters-segment {
  margin-bottom: 1rem;
}

.legislative-bill-tracker .filter-section,
.legislative-bill-tracker .sort-section {
  margin-bottom: 0.5rem;
}

.legislative-bill-tracker .filter-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-bottom: 0.5rem;
}

.legislative-bill-tracker .filter-buttons .button {
  margin: 0.25rem;
  border-radius: 999px !important;
  font-size: 0.875rem !important;
}

.legislative-bill-tracker .filter-buttons .button.active {
  background-color: #2563eb !important;
  color: white !important;
}

.legislative-bill-tracker .sort-controls {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.legislative-bill-tracker .sort-description {
  margin-left: 0.5rem;
  color: #6b7280;
  font-size: 0.875rem;
}

/* Bills grid */
.legislative-bill-tracker .bills-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

/* Bill cards */
.legislative-bill-tracker .bill-card {
  background: white;
  border: 1px solid var(--lbt-neutral-200);
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: all 0.2s ease;
  display: block;
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}

.legislative-bill-tracker .bill-card:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
  border-color: var(--lbt-primary-blue);
  text-decoration: none;
  color: inherit;
}

.legislative-bill-tracker .priority-action-card {
  border: 2px solid var(--lbt-accent-red);
}

.legislative-bill-tracker .upcoming-action-card {
  border: 2px solid #f97316;
}

/* Bill header */
.legislative-bill-tracker .bill-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.legislative-bill-tracker .bill-title {
  font-size: 18px;
  font-weight: bold;
  color: var(--lbt-primary-blue);
  display: inline-block;
}

.legislative-bill-tracker .bill-card:hover .bill-title {
  text-decoration: underline;
}

/* Impact indicator */
.legislative-bill-tracker .impact-indicator {
  font-size: 12px;
  font-weight: 600;
  padding: 4px 8px;
  border-radius: 4px;
}

.legislative-bill-tracker .high-impact {
  background: #fee2e2;
  color: #dc2626;
}

.legislative-bill-tracker .medium-impact {
  background: #fef3c7;
  color: #d97706;
}

.legislative-bill-tracker .low-impact {
  background: #dcfce7;
  color: #16a34a;
}

/* Categories */
.legislative-bill-tracker .bill-categories {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
}

.legislative-bill-tracker .category-tag {
  font-size: 12px;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--lbt-neutral-100);
  color: var(--lbt-neutral-700);
}

/* Category-specific colors */
.legislative-bill-tracker .tax-tag { background: #dbeafe; color: #1e40af; }
.legislative-bill-tracker .courts-tag { background: #fae8ff; color: #86198f; }
.legislative-bill-tracker .financial-tag { background: #dcfce7; color: #15803d; }
.legislative-bill-tracker .entities-tag { background: #fff7ed; color: #9a3412; }
.legislative-bill-tracker .foreign-tag { background: #ffe4e6; color: #be123c; }
.legislative-bill-tracker .realestate-tag { background: #f3e8ff; color: #6b21a8; }
.legislative-bill-tracker .securities-tag { background: #e0f2fe; color: #0369a1; }
.legislative-bill-tracker .liability-tag { background: #fef9c3; color: #854d0e; }
.legislative-bill-tracker .ai-tag { background: #f1f5f9; color: #0f172a; }

/* Stage indicator */
.legislative-bill-tracker .stage-indicator {
  margin-bottom: 12px;
}

.legislative-bill-tracker .stage-label {
  font-size: 14px;
  font-weight: 500;
  padding: 6px 12px;
  border-radius: 6px;
  margin-bottom: 8px;
  display: inline-block;
}

/* Stage colors */
.legislative-bill-tracker .stage-1-bg { background: #f1f5f9; color: #475569; }
.legislative-bill-tracker .stage-2-bg { background: #dbeafe; color: #1e40af; }
.legislative-bill-tracker .stage-3-bg { background: #dcfce7; color: #15803d; }
.legislative-bill-tracker .stage-4-bg { background: #fae8ff; color: #86198f; }
.legislative-bill-tracker .stage-5-bg { background: #fff7ed; color: #9a3412; }
.legislative-bill-tracker .stage-6-bg { background: #ffe4e6; color: #be123c; }
.legislative-bill-tracker .stage-7-bg { background: #f3e8ff; color: #6b21a8; }

/* Bill content */
.legislative-bill-tracker .bill-content {
  font-size: 14px;
  color: var(--lbt-neutral-700);
}

.legislative-bill-tracker .highlights-section {
  margin-bottom: 16px;
}

.legislative-bill-tracker .highlights-list {
  margin: 8px 0 0;
  padding-left: 20px;
}

.legislative-bill-tracker .highlights-list li {
  margin-bottom: 4px;
}

/* Last action styles */
.legislative-bill-tracker .last-action {
  margin-top: 16px;
  padding: 12px;
  background: var(--lbt-neutral-50);
  border-radius: 6px;
}

.legislative-bill-tracker .last-action p {
  margin: 8px 0 0 0;
  color: var(--lbt-neutral-700);
  line-height: 1.4;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.legislative-bill-tracker .last-action-date {
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  border-radius: 4px;
  background: var(--lbt-primary-light);
  color: var(--lbt-primary-blue);
  font-size: 0.9em;
  font-weight: 500;
  white-space: nowrap;
}

.legislative-bill-tracker .filing-info {
  font-size: 12px;
  color: var(--lbt-neutral-700);
  font-style: italic;
}

/* Filter styles */
.legislative-bill-tracker .ui.dropdown {
  border: 1px solid var(--lbt-neutral-200);
  border-radius: 6px;
  padding: 8px 12px;
  min-height: auto;
}

.legislative-bill-tracker .ui.dropdown:hover {
  border-color: var(--lbt-primary-blue);
}

.legislative-bill-tracker .ui.dropdown .menu {
  border: 1px solid var(--lbt-neutral-200);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.legislative-bill-tracker .ui.dropdown .menu > .item {
  padding: 8px 12px !important;
}

/* Message styles */
.legislative-bill-tracker .ui.message {
  background: var(--lbt-neutral-50);
  border: 1px solid var(--lbt-neutral-200);
  box-shadow: none;
  padding: 16px;
}

.legislative-bill-tracker .ui.message .header {
  color: var(--lbt-neutral-800);
  font-size: 16px;
  margin-bottom: 8px;
}

.legislative-bill-tracker .ui.message p {
  color: var(--lbt-neutral-700);
  font-size: 14px;
  line-height: 1.5;
}

/* Edit mode placeholder styles */
.legislative-bill-tracker-edit .placeholder-message {
  text-align: center;
  padding: 2rem;
  border: 2px dashed #d1d5db;
  border-radius: 0.5rem;
  margin: 1rem 0;
  background-color: #f9fafb;
}

.legislative-bill-tracker-edit .placeholder-message h4 {
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.legislative-bill-tracker-edit .placeholder-message p {
  color: #6b7280;
  margin-bottom: 0.5rem;
}

.ui.dropdown > .text {
  padding-right: 2rem;
}

/* Base colors */
:root {
  --lbt-primary-blue: #007bff;
  --lbt-primary-light: #e6f2ff;
  --lbt-neutral-50: #f8fafc;
  --lbt-neutral-100: #f1f5f9;
  --lbt-neutral-200: #e2e8f0;
  --lbt-neutral-300: #cbd5e1;
  --lbt-neutral-700: #334155;
  --lbt-neutral-800: #1e293b;
  --lbt-accent-red: #dc2626;
  --lbt-error-red: #ef4444;
  --lbt-error-bg: #fee2e2;
}

/* Filter chips */
.legislative-bill-tracker .filter-chip {
  background: var(--lbt-primary-light) !important;
  border: 1px solid var(--lbt-primary-blue) !important;
  color: var(--lbt-primary-blue) !important;
  padding: 6px 12px;
  border-radius: 100px;
  font-size: 13px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.legislative-bill-tracker .filter-chip:hover {
  background: var(--lbt-primary-light) !important;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.legislative-bill-tracker .filter-chip.active {
  background: var(--lbt-primary-blue) !important;
  color: white !important;
  border-color: var(--lbt-primary-blue) !important;
}

/* Active filters */
.legislative-bill-tracker .active-filters {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 12px;
}

.legislative-bill-tracker .active-filter {
  background: var(--lbt-primary-light);
  color: var(--lbt-primary-blue);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
}

.legislative-bill-tracker .active-filter .close {
  cursor: pointer;
  opacity: 0.7;
}

.legislative-bill-tracker .active-filter .close:hover {
  opacity: 1;
}

/* Sort dropdown */
.ui.dropdown {
  border-color: var(--neutral-200) !important;
}

.ui.dropdown:hover {
  border-color: var(--primary-blue) !important;
}

.ui.dropdown > .text {
  padding-right: 2rem;
  color: var(--neutral-800);
}

/* Category tags */
.category-tag {
  background: var(--primary-light);
  color: var(--primary-blue);
  border: 1px solid var(--primary-blue);
}

.tax-tag { background: #e6f2ff; color: #2563eb; }
.courts-tag { background: #ede9fe; color: #6d28d9; }
.financial-tag { background: #ecfdf5; color: #059669; }
.entities-tag { background: #fef2f2; color: #dc2626; }
.foreign-tag { background: #fff7ed; color: #ea580c; }
.realestate-tag { background: #f0f9ff; color: #0369a1; }
.securities-tag { background: #fdf2f8; color: #db2777; }
.liability-tag { background: #f5f3ff; color: #7c3aed; }
.ai-tag { background: #f0fdfa; color: #0d9488; }

/* Impact indicators */
.impact-indicator {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  font-weight: bold;
}

.high-impact {
  background: #fee2e2;
  color: #dc2626;
}

.medium-impact {
  background: #fef3c7;
  color: #d97706;
}

.low-impact {
  background: #ecfdf5;
  color: #059669;
}

/* Stage indicators */
.stage-label {
  background: var(--primary-light);
  color: var(--primary-blue);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.9em;
}

.stage-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--neutral-200);
}

.stage-dot.active {
  background: var(--primary-blue);
}

/* Priority cards */
.priority-action-card {
  border-color: var(--accent-red) !important;
}

.upcoming-action-card {
  border-color: var(--accent-orange) !important;
}

/* Action messages */
.action-message {
  background: var(--neutral-100);
  color: var(--neutral-700);
  padding: 8px;
  border-radius: 4px;
}

.action-message.urgent {
  background: #fee2e2;
  color: var(--accent-red);
}

/* Bill cards */
.bill-card {
  background: white;
  border: 1px solid var(--neutral-200);
  border-radius: 8px;
  transition: all 0.2s ease;
}

.bill-card:hover {
  border-color: var(--primary-blue);
  box-shadow: 0 4px 12px rgba(37, 99, 235, 0.1);
}

/* Links */
a.bill-title {
  color: var(--primary-blue);
  text-decoration: none;
}

a.bill-title:hover {
  color: var(--primary-dark);
  text-decoration: underline;
}

/* Buttons */
.legislative-bill-tracker .ui.button {
  background: var(--lbt-primary-blue) !important;
  color: white !important;
}

.legislative-bill-tracker .ui.button:hover {
  background: var(--lbt-primary-dark) !important;
}

.legislative-bill-tracker .ui.basic.button {
  background: transparent !important;
  color: var(--lbt-primary-blue) !important;
  border-color: var(--lbt-primary-blue) !important;
}

.legislative-bill-tracker .ui.basic.button:hover {
  background: var(--lbt-primary-light) !important;
}

/* Summary segment */
.legislative-bill-tracker .summary-segment {
  background: var(--lbt-neutral-50) !important;
  border: 1px solid var(--lbt-neutral-200) !important;
}

/* Active filters */
.legislative-bill-tracker .ui.label {
  background: var(--lbt-primary-light) !important;
  color: var(--lbt-primary-blue) !important;
  border: 1px solid var(--lbt-primary-blue) !important;
}

/* Remove toolbar-actions styles */

/* Edit mode specific styles */
.block.legislative-bill-tracker-edit {
  position: relative;
  padding: 1rem;
  background: var(--lbt-neutral-50);
  border-radius: 8px;
  margin: 1rem 0;
}

.block.legislative-bill-tracker-edit .editor-wrapper {
  position: relative;
  z-index: 1;
}

.block.legislative-bill-tracker-edit .placeholder-message {
  text-align: center;
  padding: 2rem;
  border: 2px dashed var(--lbt-neutral-300);
  border-radius: 8px;
  margin: 1rem 0;
  background: white;
}

.block.legislative-bill-tracker-edit .placeholder-message h4 {
  color: var(--lbt-neutral-800);
  margin-bottom: 0.5rem;
  font-size: 1.25rem;
}

.block.legislative-bill-tracker-edit .placeholder-message p {
  color: var(--lbt-neutral-700);
  margin-bottom: 0.5rem;
}

/* Error message styles */
.block.legislative-bill-tracker-edit .ui.negative.message {
  margin: 1rem 0;
  border-radius: 6px;
  background-color: var(--lbt-error-bg);
  color: var(--lbt-error-red);
  border: 1px solid var(--lbt-error-red);
}

.block.legislative-bill-tracker-edit .ui.negative.message .header {
  color: var(--lbt-error-red);
}

/* Stage dots */
.legislative-bill-tracker .stage-dots {
  display: flex;
  align-items: center;
  gap: 4px;
  list-style: none;
  padding: 0;
  margin: 8px 0;
}

.legislative-bill-tracker .stage-dots li {
  display: flex;
  align-items: center;
}

.legislative-bill-tracker .stage-dots li:not(:last-child)::after {
  content: '';
  display: block;
  width: 12px;
  height: 2px;
  background: var(--lbt-neutral-200);
  margin-left: 4px;
}

.legislative-bill-tracker .stage-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: var(--lbt-neutral-200);
  transition: all 0.2s ease;
}

.legislative-bill-tracker .stage-dot.active {
  background: var(--lbt-primary-blue);
  box-shadow: 0 0 0 2px var(--lbt-primary-light);
}

.legislative-bill-tracker .stage-dot:hover {
  transform: scale(1.2);
}

/* Filter chips container */
.legislative-bill-tracker .filter-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-bottom: 12px;
}

/* Accordion styles */
.legislative-bill-tracker .ui.styled.accordion {
  box-shadow: none;
  background: transparent;
}

.legislative-bill-tracker .ui.styled.accordion .title {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  color: var(--lbt-neutral-800);
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
}

.legislative-bill-tracker .ui.styled.accordion .title:hover {
  background: #f1f5f9;
}

.legislative-bill-tracker .filters-accordion .title .filter-summary {
  font-size: 14px;
  font-weight: normal;
  color: var(--lbt-neutral-600);
  margin-left: auto;
  margin-right: 16px;
}

.legislative-bill-tracker .ui.styled.accordion .content {
  padding: 16px;
  border: 1px solid #e2e8f0;
  border-top: none;
  border-radius: 0 0 6px 6px;
  margin-top: -1px;
}

.legislative-bill-tracker .ui.styled.accordion .title.active {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.legislative-bill-tracker .sort-accordion {
  margin-top: 1rem;
}

.legislative-bill-tracker .sort-accordion .content {
  padding: 12px;
}

.legislative-bill-tracker .filters-accordion .content {
  padding: 16px;
}

/* Ensure proper spacing between accordions */
.legislative-bill-tracker .filters-accordion + .sort-accordion {
  margin-top: 1rem;
}

/* Collapsible section styles */
.legislative-bill-tracker .collapsible-section {
  margin-bottom: 1rem;
}

.legislative-bill-tracker .collapsible-section:last-child {
  margin-bottom: 0;
}

.legislative-bill-tracker .collapsible-header {
  width: 100%;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  padding: 0;
  cursor: pointer;
  transition: all 0.2s ease;
}

.legislative-bill-tracker .collapsible-header:hover {
  background: #f1f5f9;
}

.legislative-bill-tracker .collapsible-header .header-content {
  display: flex;
  align-items: center;
  padding: 12px 16px;
  font-size: 16px;
  font-weight: 600;
  color: var(--lbt-neutral-800);
}

.legislative-bill-tracker .collapsible-header .filter-summary {
  font-size: 14px;
  font-weight: normal;
  color: var(--lbt-neutral-600);
  margin-left: auto;
  padding-right: 16px;
}

.legislative-bill-tracker .collapsible-header .toggle-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  font-size: 12px;
  color: var(--lbt-neutral-700);
  background: #e2e8f0;
  border-radius: 3px;
  transition: transform 0.2s ease;
}

.legislative-bill-tracker .collapsible-header:hover .toggle-icon {
  background: #cbd5e1;
  color: var(--lbt-neutral-800);
}

.legislative-bill-tracker .collapsible-header[aria-expanded="true"] .toggle-icon {
  transform: rotate(0deg);
}

.legislative-bill-tracker .collapsible-content {
  padding: 16px;
  background: #fff;
  border-radius: 0 0 8px 8px;
  transition: all 0.3s ease;
}

.legislative-bill-tracker .collapsible-content.visible {
  opacity: 1;
  transform: translateY(0);
}

.legislative-bill-tracker .sort-summary {
  margin-left: auto;
  font-size: 14px;
  color: var(--lbt-neutral-600);
}

/* Sort section specific styles */
.legislative-bill-tracker .sort-section {
  margin-top: 1rem;
}

.legislative-bill-tracker .sort-section .collapsible-content {
  padding: 12px;
  border: 1px solid #e2e8f0;
  border-top: none;
  border-radius: 0 0 6px 6px;
  margin-top: -1px;
  background: white;
}

.legislative-bill-tracker .sort-section .filter-chips {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin: 8px 0;
}

.legislative-bill-tracker .sort-section .filter-chip {
  display: inline-flex;
  align-items: center;
  padding: 6px 12px;
  border-radius: 100px;
  background: var(--lbt-neutral-100);
  color: var(--lbt-neutral-700);
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid var(--lbt-neutral-200);
}

.legislative-bill-tracker .sort-section .filter-chip:hover {
  background: var(--lbt-neutral-200);
  border-color: var(--lbt-neutral-300);
}

.legislative-bill-tracker .sort-section .filter-chip.active {
  background: var(--lbt-primary-blue);
  color: white;
  border-color: var(--lbt-primary-blue);
}

.legislative-bill-tracker .sort-summary {
  margin-left: auto;
  font-size: 14px;
  color: var(--lbt-neutral-600);
  padding-right: 8px;
}

/* Active filters container */
.legislative-bill-tracker .active-filters-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 15px;
  background: #f8f9fa;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  align-items: center;
  margin: 1rem 0;
}

.legislative-bill-tracker .active-filters-container .ui.label {
  margin: 2px;
  display: inline-flex;
  align-items: center;
  padding: 6px 10px;
  background: white !important;
  border: 1px solid #e2e8f0 !important;
  border-radius: 4px;
  font-size: 13px;
  color: var(--lbt-neutral-800) !important;
}

.legislative-bill-tracker .active-filters-container .ui.label i.icon {
  margin-left: 6px;
  opacity: 0.7;
  transition: opacity 0.2s ease;
}

.legislative-bill-tracker .active-filters-container .ui.label i.icon:hover {
  opacity: 1;
}

.legislative-bill-tracker .active-filters-container .ui.button {
  margin-left: auto;
  background: transparent !important;
  border: 1px solid #e2e8f0 !important;
  color: var(--lbt-neutral-700) !important;
  padding: 6px 12px;
  font-size: 13px;
  transition: all 0.2s ease;
}

.legislative-bill-tracker .active-filters-container .ui.button:hover {
  background: #f1f5f9 !important;
  border-color: #cbd5e1 !important;
}

/* Update collapsible section margins */
.legislative-bill-tracker .collapsible-section + .active-filters-container {
  margin-top: 1rem;
}

.legislative-bill-tracker .active-filters-container + .collapsible-section {
  margin-top: 1rem;
}

/* Active filters bar */
.legislative-bill-tracker .active-filters-bar {
  margin-top: 12px;
  padding: 8px 0;
  border-top: 1px solid #e2e8f0;
}

.legislative-bill-tracker .active-filters-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}

.legislative-bill-tracker .subtle-label {
  background: #f8fafc !important;
  border: 1px solid #e2e8f0 !important;
  color: #475569 !important;
  font-weight: 500;
  padding: 6px 12px;
  margin: 0 !important;
  font-size: 13px !important;
  display: flex;
  align-items: center;
  gap: 8px;
}

.legislative-bill-tracker .subtle-label i.icon {
  opacity: 0.7;
  margin-left: 4px !important;
  font-size: 12px !important;
  background: #e2e8f0;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.2s ease;
}

.legislative-bill-tracker .subtle-label i.icon:hover {
  opacity: 1;
  background: #cbd5e1;
  color: #1e293b;
}

.legislative-bill-tracker .clear-filters-button {
  background: transparent !important;
  border: 1px solid #cbd5e1 !important;
  color: #64748b !important;
  padding: 6px 12px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  margin-left: auto !important;
  height: auto !important;
  line-height: 1 !important;
  transition: all 0.2s ease !important;
}

.legislative-bill-tracker .clear-filters-button:hover {
  background: #f8fafc !important;
  color: #475569 !important;
  border-color: #94a3b8 !important;
}

.legislative-bill-tracker .filter-label {
  display: inline-flex;
  align-items: center;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  padding: 6px 8px 6px 12px;
  font-size: 13px;
  color: #475569;
  font-weight: 500;
  gap: 8px;
}

.legislative-bill-tracker .filter-label span {
  line-height: 1;
}

.legislative-bill-tracker .filter-label .close-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  background: #e2e8f0;
  color: #64748b;
  border-radius: 50%;
  cursor: pointer;
  font-size: 16px;
  line-height: 1;
  transition: all 0.2s ease;
}

.legislative-bill-tracker .filter-label .close-button:hover {
  background: #cbd5e1;
  color: #1e293b;
}

.legislative-bill-tracker .active-filters-content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
}

.legislative-bill-tracker .direction-toggle-button {
  background: transparent;
  border: 1px solid #e2e8f0;
  border-radius: 20px;
  padding: 6px 12px;
  font-size: 13px;
  color: #475569;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.legislative-bill-tracker .direction-toggle-button:hover {
  background: #f1f5f9;
  border-color: #cbd5e1;
  color: #1e293b;
} 